<template>
  <!-- modal add début-->

  <div class="modal-dialog modal-dialog-centered">
    <form
      @submit.prevent="confirm"
      class="modal-content border-0 bg-light text-dark"
    >
      <div class="modal-header mx-2">
        <h4 class="modal-title text-primary" id="modalLabelAjout">
          Ajouter un nouveau type
        </h4>
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
      <div class="modal-body">
        <section class="row p-2">
          <div class="col-12 mb-3">
            <label for="inputMail1" class="form-label">Identification</label>
            <input
              type="text"
              class="form-control"
              required
              v-model="form.name_type_offre"
            />
          </div>
        </section>
      </div>
      <div class="modal-footer mx-2">
        <button
          type="button"
          class="btn btn-secondary me-0"
          data-bs-dismiss="modal"
          ref="close"
        >
          Annuler
        </button>
        <button v-if="loading" class="btn btn-primary" type="button" disabled>
          <span
            class="spinner-grow spinner-grow-sm"
            role="status"
            aria-hidden="true"
          ></span>
          Envoi...
        </button>
        <button v-else type="submit" class="btn btn-primary ms-3">
          Confirmer
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { postTypeOffre } from "../../api/typeOffre";
import { success, error } from "../../utils/toast";

export default {
  name: "ModalAddType",
  data() {
    return {
      form: {
        name_type_offre: "",
      },
      loading: false,
    };
  },
  methods: {
    confirm() {
      this.loading = true;
      postTypeOffre(this.form).then((result) => {
        if (result.data.error) {
          this.loading = false;
          error(result.data.error, "");
        } else {
          this.loading = false;
          this.$refs.close.click();
          this.form.name_type_offre = "";
          success("Type ajouté");
          this.$emit("fetch");
        }
      });
    },
  },
};
</script>
